import React from "react";
import { Link } from "gatsby";
// import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import hfpm from "../../images/henry-flats-plat-map.jpg";
import hnpm from "../../images/heritage-north-plat-map.jpg";
import rcpm from "../../images/remuda-court-plat-map.jpg";
import tppm from "../../images/taylor-parks-plat-map.jpg";
import sr3pm from "../../images/sunset-ridge-phase-3-plat-map.jpg";
import sr4pm from "../../images/sunset-ridge-phase-4-plat-map.jpg";
import mhmpm from "../../images/meyerhoffer-meadows-plat-map.jpg";
import "../../css/plans.css";

class CommunitiesPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container mb-50">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Communities</h1>
            </div>
          </div>

          <div className="info-banner">
            <div className="row">

              <div className="col-sm-12 d-flex align-items-right justify-content-center">
                  Contact us for lot availability.
              </div>
              <div className="col-sm-12 d-flex align-items-right justify-content-center">
                <Link to="/contact-us"><button type="button" className="btn btn-success btn-lg mt-3">CONTACT</button></Link>
              </div>

            </div>
          </div>


          <div className="row mt-4">
            <div className="col-sm-6">

              {/* <Link to="/communities/heritage-north-subdivision"> */}
                <h3>Sunset Ridge Phase 3</h3>

                <div>
                  <img src={sr3pm} className="img-fluid" alt="alt"/>
                </div>

              {/* </Link> */}

            </div>
              <div className="col-sm-6">

              <h3>Sunset Ridge Phase 4</h3>

                <div>
                  <img src={sr4pm} className="img-fluid" alt="alt"/>
                </div>

              </div>
          </div>


          <div className="row mt-4">
            <div className="col-sm-6">

              {/* <Link to="/communities/heritage-north-subdivision"> */}
                <h3>Meyerhoffer Meadows</h3>

                <div>
                  <img src={mhmpm} className="img-fluid" alt="alt"/>
                </div>

              {/* </Link> */}

            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-6">

              {/* <Link to="/communities/heritage-north-subdivision"> */}
                <h3>Heritage North</h3>

                <div>
                  <img src={hnpm} className="img-fluid" alt="alt"/>
                </div>

                <div className="sold-out">SOLD OUT</div>

              {/* </Link> */}

            </div>
              <div className="col-sm-6">

                <h3>Henry Flats</h3>

                <div>
                  <img src={hfpm} className="img-fluid" alt="alt"/>
                </div>

                <div className="sold-out">SOLD OUT</div>

              </div>
          </div>



          <div className="row mt-4">
            <div className="col-sm-6">

                <h3>Taylor Parks Phase 3</h3>

                <div>
                  <img src={tppm} className="img-fluid" alt="alt"/>
                </div>

                <div className="sold-out">SOLD OUT</div>

            </div>
              <div className="col-sm-6">

                  <h3>Palmers Heritage</h3>

                  <div>
                    <img src={tppm} className="img-fluid" alt="alt"/>
                  </div>

                  <div className="sold-out">SOLD OUT</div>

              </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-6">

              {/*<Link to="/communities/taylor-parks-subdivision">*/}
                <h3>Taylor Parks Phase 2</h3>

                <div>
                  <img src={tppm} className="img-fluid" alt="alt"/>
                </div>

                <div className="sold-out">SOLD OUT</div>

              {/*</Link>*/}

            </div>
              <div className="col-sm-6">

                {/*<Link to="/communities/remuda-court-subdivision">*/}

                  <h3>Remuda Court</h3>

                  <div>
                    <img src={rcpm} className="img-fluid" alt="alt"/>
                  </div>

                  <div className="sold-out">SOLD OUT</div>

                {/*</Link>*/}

              </div>
          </div>

        </div>

      </Layout>
    )
  }
};

export default CommunitiesPage;
